












import { Component, Vue } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import { Tab } from "@/interfaces";
import { ProjectType } from "@/entity/Project";

@Component({
  components: {
    Tabs,
  },
})
export default class ProjectsLayout extends Vue {
  get tabs(): Tab[] {
    return [
      {
        routeName: "transactionProjects",
        name: this.$t("transaction").toString(),
      },
      {
        routeName: "valuationProjects",
        name: this.$t("valuation").toString(),
      },
    ];
  }

  get projectType(): ProjectType {
    if (this.$route.name === "transactionProjects") {
      return ProjectType.transaction;
    } else if (this.$route.name === "valuationProjects") {
      return ProjectType.valuation;
    } else {
      throw new Error("Unknown project type");
    }
  }
}
